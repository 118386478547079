import { useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { AnnotationLayers } from './AnnotationLayers';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { TimelineVideo } from '../../TimelineVideo';
import { calculateDisplayStreamSizes } from '../../../../../../services/engine/engine-service';
import { applyBlur } from '../../../../../../helpers/image/image-helper';

export const FrameViewer = ({ selectedSituation, isCommentModalOpen, isViewMode, handleFixError }) => {
  const { specification_frames: unorderedFrames } = selectedSituation;
  const frames = unorderedFrames.sort((a, b) => a.id - b.id);

  const {
    canvasRef,
    selectedFrame,
    setSelectedFrame,
    isVideoSituation,
    setIsVideoSituation,
    guidePopup,
    setIsInitialSegmentationDone,
  } = useAnnotation();

  const { width, height, ref: imageContainerRef } = useResizeDetector();

  const holderViewRef = useRef();

  const [frameSizes, setFrameSizes] = useState({
    width: 0,
    height: 0,
    ratio: 0,
  });

  const [originalFrameSelected, setOriginalFrameSelected] = useState(null);

  // Size that maintains responsiveness while preserving the aspect ratio of the base frame.
  const displaySizes = useMemo(() => {
    const { width: frameWidth, height: frameHeight, ratio: frameRatio } = frameSizes;
    return calculateDisplayStreamSizes(frameWidth, frameHeight, frameRatio, width, height);
  }, [width, height, frameSizes, canvasRef.current]);

  const getContainerStyle = () => {
    if (guidePopup?.id === 'creation_segment') {
      return { zIndex: 50 };
    } else {
      return {};
    }
  };

  // Retrieves the frames corresponding to the situation and draws these frames in the canvas.
  useEffect(() => {
    if (selectedFrame) {
      console.log('[DEBUG] Retrieve frame');
      const bbox = selectedFrame.bbox;
      const context = canvasRef.current.getContext('2d');

      const drawFrame = () => {
        try {
          const framePath = `${process.env.REACT_APP_FILE_URL}${selectedFrame.filename}`;
          const img = new Image();
          img.crossOrigin = 'anonymous';
          img.src = framePath;
          setOriginalFrameSelected(framePath);

          img.onload = () => {
            const { width, height } = img;
            const ratio = width / height;

            canvasRef.current.width = width;
            canvasRef.current.height = height;
            setFrameSizes({ width: img.width, height: img.height, ratio: ratio });

            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            context.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height);

            if (bbox?.length > 0) {
              console.log('[DEBUG] Applying blur to bbox');
              bbox.forEach(([x1, y1, x2, y2]) => {
                applyBlur(context, x1, y1, x2 - x1, y2 - y1, canvasRef);
              });
            }
          };

          img.onerror = (err) => {
            console.error('Error loading image', err);
          };
        } catch (error) {
          console.error('Error loading frame:', error);
        }
      };

      drawFrame();
    } else {
      const context = canvasRef.current.getContext('2d');
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  }, [selectedFrame]);

  useEffect(() => {
    if (frames?.length > 1) {
      console.log('[DEBUG] Update frame with first frame 0');
      setIsInitialSegmentationDone(false);
      setSelectedFrame(frames[0]);
      setIsVideoSituation(true);
    } else if (frames?.length) {
      console.log('[DEBUG] Update frame with first frame 1');
      setIsInitialSegmentationDone(false);
      setSelectedFrame(frames[0]);
      setIsVideoSituation(false);
    }
  }, [frames]);

  return (
    <div className='flex flex-col items-center justify-center flex-auto gap-4 overflow-hidden' ref={imageContainerRef}>
      <div style={displaySizes} className='relative' ref={holderViewRef}>
        <div className='relative w-full h-full' style={getContainerStyle()}>
          <AnnotationLayers
            selectedFrame={selectedFrame}
            displaySizes={displaySizes}
            frameSizes={frameSizes}
            holderViewRef={holderViewRef}
            isCommentModalOpen={isCommentModalOpen}
            isViewMode={isViewMode}
            handleFixError={handleFixError}
            originalFrameSelected={originalFrameSelected}
          >
            <canvas ref={canvasRef} className='w-full h-full' />
          </AnnotationLayers>
        </div>
        <OverlayFilter guidePopup={guidePopup} isCommentModalOpen={isCommentModalOpen} />
      </div>
      {isVideoSituation && (
        <div className='w-full'>
          <TimelineVideo frames={frames} selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} />
        </div>
      )}
    </div>
  );
};

const OverlayFilter = ({ guidePopup, isCommentModalOpen }) => {
  if (guidePopup) return null;
  return (
    <div
      // TODO [WIP] Enable if IA services are running
      // className={`absolute inset-0 bg-black ${isCommentModalOpen ? 'opacity-70' : 'opacity-25'} pointer-events-none`}
      className={`absolute inset-0 ${isCommentModalOpen ? 'opacity-70' : 'opacity-25'} pointer-events-none`}
    />
  );
};
