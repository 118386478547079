import { SegmentationLayer } from './SegmentationLayer';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { CircleMarker } from './CircleMarker';
import { Fragment, useLayoutEffect, useMemo, useState } from 'react';
import { AnnotationModal } from '../modals/AnnotationModal';
import { Button } from '../../../../../../components/buttons/Button';

export const AnnotationLayers = ({
  selectedFrame,
  frameSizes,
  displaySizes,
  holderViewRef,
  isCommentModalOpen,
  children,
  isViewMode,
  handleFixError,
  originalFrameSelected,
}) => {
  const { clickedPixel, markerHovered, annotations, guidePopup } = useAnnotation();

  // TODO [WIP] Enable if IA services are running
  const [isSegmentationLoad, setIsSegmentationLoad] = useState(false);

  const isModalOpen = !!clickedPixel || !!markerHovered;

  // Store all the markers that need to be displayed on the image, such as clicked points, previously saved annotations, etc.
  const markers = useMemo(() => {
    if (annotations?.length > 0) {
      // If a pixel has been clicked, then we want to display only that marker on the image.
      if (clickedPixel) {
        return [clickedPixel];
      } else {
        const segmentAnnotations = annotations.filter((annotation) => annotation.position.y);
        const annotationFilteredBySelectedFrame = segmentAnnotations.filter(
          (annotation) => annotation.specification_frame.filename === selectedFrame.filename,
        );
        const updatedMarkers = [...annotationFilteredBySelectedFrame];

        return updatedMarkers;
      }
    } else {
      if (clickedPixel) {
        return [clickedPixel];
      } else {
        return [];
      }
    }
  }, [annotations, selectedFrame]);

  return (
    <Fragment>
      {/* LAYERS */}
      <SegmentationLayer
        frameSizes={frameSizes}
        displaySizes={displaySizes}
        isCommentModalOpen={isCommentModalOpen}
        isViewMode={isViewMode}
        isSegmentationLoad={isSegmentationLoad}
        setIsSegmentationLoad={setIsSegmentationLoad}
        originalFrameSelected={originalFrameSelected}
      />
      <Markers
        markers={markers}
        frameSizes={frameSizes}
        displaySizes={displaySizes}
        isCommentModalOpen={isCommentModalOpen}
        isSegmentationLoad={isSegmentationLoad}
      />
      {children}
      {/* MODAL & POPUP */}
      {isModalOpen ? (
        <AnnotationModal
          frameSizes={frameSizes}
          displaySizes={displaySizes}
          isViewMode={isViewMode}
          handleFixError={handleFixError}
        />
      ) : null}
      {guidePopup && <GuidePopup holderViewRef={holderViewRef} />}
    </Fragment>
  );
};

const GuidePopup = ({ holderViewRef }) => {
  const { guidePopup, setGuidePopup } = useAnnotation();

  const [popupStyle, setPopupStyle] = useState({});

  const handleClose = () => {
    setGuidePopup(null);
  };

  useLayoutEffect(() => {
    if (holderViewRef.current) {
      const parentRect = holderViewRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      const popupWidth = 200;
      const popupLeft = parentRect.right + 10;

      if (popupLeft + popupWidth > windowWidth) {
        setPopupStyle({
          position: 'fixed',
          top: parentRect.top + window.scrollY + 'px',
          left: 'auto',
          right: 10 + 'px',
        });
      } else {
        setPopupStyle({
          position: 'fixed',
          top: parentRect.top + window.scrollY + 'px',
          left: popupLeft + 'px',
        });
      }
    }
  }, [holderViewRef]);

  return (
    <div className='fixed z-50 p-4 shadow-lg' style={popupStyle}>
      <div className='relative p-6 text-white bg-perception-blue'>
        <div className='absolute left-0 w-0 h-0 transform -translate-x-full -translate-y-1/2 border-t-8 border-b-8 border-r-8 top-1/2 border-t-transparent border-b-transparent border-r-perception-blue'></div>
        <p className='text-lg font-semibold'>{guidePopup.title}</p>
        <p className='pt-2 text-sm'>{guidePopup.content}</p>
        <div className='flex items-center justify-between pt-2'>
          <div className='ml-auto'>
            <Button category='secondary-btn' size='xs' className='border-none' onClick={handleClose}>
              Got it
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Markers = ({ markers, frameSizes, displaySizes, isCommentModalOpen, isSegmentationLoad }) => {
  if (!markers.length || isSegmentationLoad || isCommentModalOpen) return null;

  const renderedMarkers = markers.map((marker) => (
    <CircleMarker key={marker.id} marker={marker} frameSizes={frameSizes} displaySizes={displaySizes} />
  ));

  return renderedMarkers;
};
