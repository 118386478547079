import { Fragment, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getLearningStateName } from '../../../../services/entity/event-type/event-type-service';
import { ChartBarSquareIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';

const chartOption = {
  scales: {
    x: {
      grid: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
    y: {
      grid: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
  },
};

const tableHeaders = [`event name`, `learning status`, 'AI confidence', 'actions'];

export const EventsTable = ({ activeEventTypes }) => {
  const [chartData, setChartData] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });

  const eventTypes = activeEventTypes.filter((eventType) => eventType.subtype > 100);

  return (
    <Fragment>
      <table className='w-full mt-4'>
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                className='px-4 py-2 font-normal text-left w-1/8 bg-perception-gray-800 text-perception-gray-500'
              >
                {header}
              </th>
            ))}
            <th className='p-4 text-left w-1/8 bg-perception-gray-800'></th>
          </tr>
        </thead>
        <tbody>
          {eventTypes.map((eventType, index) => {
            return (
              <EventTypeRow
                key={index}
                eventType={eventType}
                setHoverPosition={setHoverPosition}
                setChartData={setChartData}
              />
            );
          })}
        </tbody>
      </table>
      {chartData && (
        <div
          className='fixed'
          style={{
            left: `${hoverPosition.x}px`,
            top: `${hoverPosition.y}px`,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          <Line data={chartData} options={chartOption} />
        </div>
      )}
    </Fragment>
  );
};

const EventTypeRow = ({ eventType, setHoverPosition, setChartData }) => {
  const navigate = useNavigate();
  const { subtype } = useParams();

  // The line is highlighted if the user has been redirected to this page by clicking on a log in the log panel.
  const isHighlightedBatch = Number(subtype) === eventType.subtype;
  const highlightClass = isHighlightedBatch ? 'animate-pulse-bg-once' : '';

  const sortedConfidence = eventType.event_types_confidences.sort((a, b) => b.date - a.date);
  const latestConfidence = sortedConfidence[0];

  const getColor = (eventType) => {
    if (eventType.learning_state === 1) return 'text-orange-400';
    else if (eventType.learning_state === 2) return 'text-green-400';
    else return 'text-red-400';
  };

  const handleEventClick = (subtypeId) => {
    navigate(`/production/${subtypeId}`);
  };

  const handleMouseMove = (e, row) => {
    const graphWidth = 400;
    const graphHeight = 200;
    setHoverPosition({ x: window.innerWidth - graphWidth, y: e.pageY - graphHeight });
    handleMouseEnter(row);
  };

  const handleMouseLeave = () => {
    setChartData(null);
  };

  const handleMouseEnter = (row) => {
    const sortedEventTypeConfidence = row.event_types_confidences.sort((a, b) => a.date - b.date);

    const chartData = {
      labels: sortedEventTypeConfidence.map((_, index) => `S${index + 1}`),
      datasets: [
        {
          label: row.name,
          data: sortedEventTypeConfidence.map((eventTypeConfidence) => eventTypeConfidence.confidence),
          backgroundColor: 'rgba(75,192,192,0.9)',
          borderColor: 'rgba(75,192,192,0.9)',
          borderWidth: 1,
          fill: false,
        },
      ],
    };

    setChartData(chartData);
  };

  return (
    <tr
      className={`border-b border-perception-gray-500/45 ${highlightClass} text-left text-sm text-perception-gray-500`}
    >
      <td className='p-4'>{eventType.name}</td>
      <td className='p-4'>{getLearningStateName(eventType)}</td>

      <td className={`p-4  ${getColor(eventType)}`}>
        {latestConfidence?.confidence ? latestConfidence.confidence : 0}%
      </td>
      <td className='flex p-4 '>
        <ChartBarSquareIcon
          className='w-5 h-5 cursor-pointer'
          onMouseMove={(e) => handleMouseMove(e, eventType)}
          onMouseLeave={handleMouseLeave}
        />
        <MagnifyingGlassCircleIcon
          className='w-5 h-5 ml-2 cursor-pointer'
          onClick={() => handleEventClick(eventType.subtype)}
        />
      </td>
    </tr>
  );
};
